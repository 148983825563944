var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useEffect, useMemo, useState } from 'react';
import { MAX_QUERY_CHANNELS_LIMIT } from '../utils';
export var usePaginatedChannels = function (client, filters, sort, options, activeChannelHandler) {
    var _a = useState([]), channels = _a[0], setChannels = _a[1];
    var _b = useState(false), error = _b[0], setError = _b[1];
    var _c = useState(true), hasNextPage = _c[0], setHasNextPage = _c[1];
    var _d = useState(true), loadingChannels = _d[0], setLoadingChannels = _d[1];
    var _e = useState(true), refreshing = _e[0], setRefreshing = _e[1];
    var filterString = useMemo(function () { return JSON.stringify(filters); }, [filters]);
    var sortString = useMemo(function () { return JSON.stringify(sort); }, [sort]);
    var queryChannels = function (queryType) { return __awaiter(void 0, void 0, void 0, function () {
        var offset, newOptions, channelQueryResponse, newChannels, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setError(false);
                    if (queryType === 'reload') {
                        setChannels([]);
                        setLoadingChannels(true);
                    }
                    setRefreshing(true);
                    offset = queryType === 'reload' ? 0 : channels.length;
                    newOptions = __assign({ limit: (_a = options === null || options === void 0 ? void 0 : options.limit) !== null && _a !== void 0 ? _a : MAX_QUERY_CHANNELS_LIMIT, offset: offset }, options);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.queryChannels(filters, sort || {}, newOptions)];
                case 2:
                    channelQueryResponse = _b.sent();
                    newChannels = queryType === 'reload' ? channelQueryResponse : __spreadArray(__spreadArray([], channels), channelQueryResponse);
                    setChannels(newChannels);
                    setHasNextPage(channelQueryResponse.length >= newOptions.limit);
                    // Set active channel only on load of first page
                    if (!offset && activeChannelHandler) {
                        activeChannelHandler(newChannels, setChannels);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _b.sent();
                    console.warn(err_1);
                    setError(true);
                    return [3 /*break*/, 4];
                case 4:
                    setLoadingChannels(false);
                    setRefreshing(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadNextPage = function () {
        queryChannels();
    };
    useEffect(function () {
        queryChannels('reload');
    }, [filterString, sortString]);
    return {
        channels: channels,
        hasNextPage: hasNextPage,
        loadNextPage: loadNextPage,
        setChannels: setChannels,
        status: {
            error: error,
            loadingChannels: loadingChannels,
            refreshing: refreshing,
        },
    };
};
