var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactPlayer from 'react-player';
import { AttachmentActions as DefaultAttachmentActions } from './AttachmentActions';
import { Audio as DefaultAudio } from './Audio';
import { Card as DefaultCard } from './Card';
import { FileAttachment as DefaultFile } from './FileAttachment';
import { Gallery as DefaultGallery, ImageComponent as DefaultImage } from '../Gallery';
export var SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/ogg', 'video/webm', 'video/quicktime'];
export var isGalleryAttachmentType = function (output) { return output.images != null; };
export var isAudioAttachment = function (attachment) { return attachment.type === 'audio'; };
export var isFileAttachment = function (attachment) {
    return attachment.type === 'file' ||
        (attachment.mime_type &&
            SUPPORTED_VIDEO_FORMATS.indexOf(attachment.mime_type) === -1 &&
            attachment.type !== 'video');
};
export var isImageAttachment = function (attachment) { return attachment.type === 'image' && !attachment.title_link && !attachment.og_scrape_url; };
export var isMediaAttachment = function (attachment) {
    return (attachment.mime_type && SUPPORTED_VIDEO_FORMATS.indexOf(attachment.mime_type) !== -1) ||
        attachment.type === 'video';
};
export var renderAttachmentWithinContainer = function (props) {
    var attachment = props.attachment, children = props.children, componentType = props.componentType;
    var extra = '';
    if (!isGalleryAttachmentType(attachment)) {
        extra =
            componentType === 'card' && !(attachment === null || attachment === void 0 ? void 0 : attachment.image_url) && !(attachment === null || attachment === void 0 ? void 0 : attachment.thumb_url)
                ? 'no-image'
                : attachment && attachment.actions && attachment.actions.length
                    ? 'actions'
                    : '';
    }
    return (React.createElement("div", { className: "str-chat__message-attachment str-chat__message-attachment--" + componentType + " str-chat__message-attachment--" + ((attachment === null || attachment === void 0 ? void 0 : attachment.type) || '') + " str-chat__message-attachment--" + componentType + "--" + extra, key: (isGalleryAttachmentType(attachment) ? '' : attachment === null || attachment === void 0 ? void 0 : attachment.id) + "-" + ((attachment === null || attachment === void 0 ? void 0 : attachment.type) || 'none') + " " }, children));
};
export var renderAttachmentActions = function (props) {
    var _a;
    var actionHandler = props.actionHandler, attachment = props.attachment, _b = props.AttachmentActions, AttachmentActions = _b === void 0 ? DefaultAttachmentActions : _b;
    if (!((_a = attachment.actions) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    return (React.createElement(AttachmentActions, __assign({}, attachment, { actionHandler: function (event, name, value) { return actionHandler === null || actionHandler === void 0 ? void 0 : actionHandler(event, name, value); }, actions: attachment.actions, id: attachment.id || '', key: "key-actions-" + attachment.id, text: attachment.text || '' })));
};
export var renderGallery = function (props) {
    var attachment = props.attachment, _a = props.Gallery, Gallery = _a === void 0 ? DefaultGallery : _a;
    return renderAttachmentWithinContainer({
        attachment: attachment,
        children: React.createElement(Gallery, { images: attachment.images || [], key: 'gallery' }),
        componentType: 'gallery',
    });
};
export var renderImage = function (props) {
    var attachment = props.attachment, _a = props.Image, Image = _a === void 0 ? DefaultImage : _a;
    if (attachment.actions && attachment.actions.length) {
        return renderAttachmentWithinContainer({
            attachment: attachment,
            children: (React.createElement("div", { className: 'str-chat__attachment', key: "key-image-" + attachment.id },
                React.createElement(Image, __assign({}, attachment)),
                renderAttachmentActions(props))),
            componentType: 'image',
        });
    }
    return renderAttachmentWithinContainer({
        attachment: attachment,
        children: React.createElement(Image, __assign({}, attachment, { key: "key-image-" + attachment.id })),
        componentType: 'image',
    });
};
export var renderCard = function (props) {
    var attachment = props.attachment, _a = props.Card, Card = _a === void 0 ? DefaultCard : _a;
    if (attachment.actions && attachment.actions.length) {
        return renderAttachmentWithinContainer({
            attachment: attachment,
            children: (React.createElement("div", { className: 'str-chat__attachment', key: "key-image-" + attachment.id },
                React.createElement(Card, __assign({}, attachment, { key: "key-card-" + attachment.id })),
                renderAttachmentActions(props))),
            componentType: 'card',
        });
    }
    return renderAttachmentWithinContainer({
        attachment: attachment,
        children: React.createElement(Card, __assign({}, attachment, { key: "key-card-" + attachment.id })),
        componentType: 'card',
    });
};
export var renderFile = function (props) {
    var attachment = props.attachment, _a = props.File, File = _a === void 0 ? DefaultFile : _a;
    if (!attachment.asset_url)
        return null;
    return renderAttachmentWithinContainer({
        attachment: attachment,
        children: React.createElement(File, { attachment: attachment, key: "key-file-" + attachment.id }),
        componentType: 'file',
    });
};
export var renderAudio = function (props) {
    var attachment = props.attachment, _a = props.Audio, Audio = _a === void 0 ? DefaultAudio : _a;
    return renderAttachmentWithinContainer({
        attachment: attachment,
        children: (React.createElement("div", { className: 'str-chat__attachment', key: "key-video-" + attachment.id },
            React.createElement(Audio, { og: attachment }))),
        componentType: 'audio',
    });
};
export var renderMedia = function (props) {
    var _a;
    var attachment = props.attachment, _b = props.Media, Media = _b === void 0 ? ReactPlayer : _b;
    if ((_a = attachment.actions) === null || _a === void 0 ? void 0 : _a.length) {
        return renderAttachmentWithinContainer({
            attachment: attachment,
            children: (React.createElement("div", { className: 'str-chat__attachment str-chat__attachment-media', key: "key-video-" + attachment.id },
                React.createElement("div", { className: 'str-chat__player-wrapper' },
                    React.createElement(Media, { className: 'react-player', controls: true, height: '100%', url: attachment.asset_url, width: '100%' })),
                renderAttachmentActions(props))),
            componentType: 'media',
        });
    }
    return renderAttachmentWithinContainer({
        attachment: attachment,
        children: (React.createElement("div", { className: 'str-chat__player-wrapper', key: "key-video-" + attachment.id },
            React.createElement(Media, { className: 'react-player', controls: true, height: '100%', url: attachment.asset_url, width: '100%' }))),
        componentType: 'media',
    });
};
