import React from 'react';
import { LoadingChannels } from '../Loading/LoadingChannels';
import placeholder from '../../assets/str-chat__connection-error.svg';
import { useTranslationContext } from '../../context/TranslationContext';
var UnMemoizedChatDown = function (props) {
    var image = props.image, text = props.text, _a = props.type, type = _a === void 0 ? 'Error' : _a;
    var t = useTranslationContext('ChatDown').t;
    return (React.createElement("div", { className: 'str-chat__down' },
        React.createElement(LoadingChannels, null),
        React.createElement("div", { className: 'str-chat__down-main' },
            React.createElement("img", { alt: 'Connection error', "data-testid": 'chatdown-img', src: image || placeholder }),
            React.createElement("h1", null, type),
            React.createElement("h3", { "aria-live": 'assertive' }, text || t('Error connecting to chat, refresh the page to try again.')))));
};
/**
 * A simple indicator that chat functionality isn't available, triggered when the Chat API is unavailable or your network isn't working.
 */
export var ChatDown = React.memo(UnMemoizedChatDown);
