import React, { useEffect, useState } from 'react';
import { useChatContext } from '../../../context/ChatContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
export var CooldownTimer = function (props) {
    var cooldownInterval = props.cooldownInterval, setCooldownRemaining = props.setCooldownRemaining;
    var _a = useState(cooldownInterval), seconds = _a[0], setSeconds = _a[1];
    useEffect(function () {
        var countdownInterval = setInterval(function () {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            else {
                setCooldownRemaining(0);
            }
        }, 1000);
        return function () { return clearInterval(countdownInterval); };
    });
    return React.createElement("div", null, seconds === 0 ? null : seconds);
};
var rolesToSkip = {
    admin: true,
    channel_moderator: true,
    moderator: true,
};
var checkUserRoles = function (globalRole, channelRole) {
    var skipGlobal = !!rolesToSkip[globalRole];
    var skipChannel = !!rolesToSkip[channelRole];
    return skipGlobal || skipChannel;
};
export var useCooldownTimer = function () {
    var _a, _b;
    var client = useChatContext('useCooldownTimer').client;
    var channel = useChannelStateContext('useCooldownTimer').channel;
    var cooldownInterval = (channel.data || {}).cooldown;
    var _c = useState(), cooldownRemaining = _c[0], setCooldownRemaining = _c[1];
    var globalRole = ((_a = client.user) === null || _a === void 0 ? void 0 : _a.role) || '';
    var channelRole = ((_b = channel.state.members[client.userID || '']) === null || _b === void 0 ? void 0 : _b.role) || '';
    var skipCooldown = checkUserRoles(globalRole, channelRole);
    useEffect(function () {
        var handleEvent = function (event) {
            var _a;
            if (!skipCooldown && ((_a = event.user) === null || _a === void 0 ? void 0 : _a.id) === client.userID) {
                setCooldownRemaining(cooldownInterval);
            }
        };
        if (cooldownInterval)
            channel.on('message.new', handleEvent);
        return function () { return channel.off('message.new', handleEvent); };
    }, [channel.id, cooldownInterval]);
    return { cooldownInterval: cooldownInterval || 0, cooldownRemaining: cooldownRemaining, setCooldownRemaining: setCooldownRemaining };
};
