import React from 'react';
import { SafeAnchor } from '../SafeAnchor';
import { useTranslationContext } from '../../context/TranslationContext';
var UnMemoizedCard = function (props) {
    var image_url = props.image_url, og_scrape_url = props.og_scrape_url, text = props.text, thumb_url = props.thumb_url, title = props.title, title_link = props.title_link, type = props.type;
    var t = useTranslationContext('Card').t;
    var image = thumb_url || image_url;
    var trimUrl = function (url) {
        if (url !== undefined && url !== null) {
            var trimmedUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
            return trimmedUrl;
        }
        return null;
    };
    if (!title && !title_link && !image) {
        return (React.createElement("div", { className: "str-chat__message-attachment-card str-chat__message-attachment-card--" + type },
            React.createElement("div", { className: 'str-chat__message-attachment-card--content' },
                React.createElement("div", { className: 'str-chat__message-attachment-card--text' }, t('this content could not be displayed')))));
    }
    if (!title_link && !og_scrape_url) {
        return null;
    }
    return (React.createElement("div", { className: "str-chat__message-attachment-card str-chat__message-attachment-card--" + type },
        image && (React.createElement("div", { className: 'str-chat__message-attachment-card--header' },
            React.createElement("img", { alt: image, src: image }))),
        React.createElement("div", { className: 'str-chat__message-attachment-card--content' },
            React.createElement("div", { className: 'str-chat__message-attachment-card--flex' },
                title && React.createElement("div", { className: 'str-chat__message-attachment-card--title' }, title),
                text && React.createElement("div", { className: 'str-chat__message-attachment-card--text' }, text),
                (title_link || og_scrape_url) && (React.createElement(SafeAnchor, { className: 'str-chat__message-attachment-card--url', href: title_link || og_scrape_url, rel: 'noopener noreferrer', target: '_blank' }, trimUrl(title_link || og_scrape_url)))))));
};
/**
 * Simple Card Layout for displaying links
 */
export var Card = React.memo(UnMemoizedCard);
