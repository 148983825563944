import React from 'react';
import { FileUploadButton, ImageDropzone } from 'react-file-utils';
import { EmojiPicker } from './EmojiPicker';
import { CooldownTimer as DefaultCooldownTimer } from './hooks/useCooldownTimer';
import { EmojiIconLarge as DefaultEmojiIcon, FileUploadIconFlat as DefaultFileUploadIcon, SendButton as DefaultSendButton, } from './icons';
import { QuotedMessagePreview as DefaultQuotedMessagePreview } from './QuotedMessagePreview';
import { UploadsPreview } from './UploadsPreview';
import { ChatAutoComplete } from '../ChatAutoComplete/ChatAutoComplete';
import { Tooltip } from '../Tooltip/Tooltip';
import { useChannelStateContext } from '../../context/ChannelStateContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { useMessageInputContext } from '../../context/MessageInputContext';
import { useComponentContext } from '../../context/ComponentContext';
export var MessageInputFlat = function () {
    var _a = useChannelStateContext('MessageInputFlat'), acceptedFiles = _a.acceptedFiles, multipleUploads = _a.multipleUploads, quotedMessage = _a.quotedMessage;
    var t = useTranslationContext('MessageInputFlat').t;
    var _b = useMessageInputContext('MessageInputFlat'), closeEmojiPicker = _b.closeEmojiPicker, cooldownInterval = _b.cooldownInterval, cooldownRemaining = _b.cooldownRemaining, emojiPickerIsOpen = _b.emojiPickerIsOpen, handleSubmit = _b.handleSubmit, isUploadEnabled = _b.isUploadEnabled, maxFilesLeft = _b.maxFilesLeft, openEmojiPicker = _b.openEmojiPicker, setCooldownRemaining = _b.setCooldownRemaining, uploadNewFiles = _b.uploadNewFiles;
    var _c = useComponentContext('MessageInputFlat'), _d = _c.CooldownTimer, CooldownTimer = _d === void 0 ? DefaultCooldownTimer : _d, _e = _c.EmojiIcon, EmojiIcon = _e === void 0 ? DefaultEmojiIcon : _e, _f = _c.FileUploadIcon, FileUploadIcon = _f === void 0 ? DefaultFileUploadIcon : _f, _g = _c.QuotedMessagePreview, QuotedMessagePreview = _g === void 0 ? DefaultQuotedMessagePreview : _g, _h = _c.SendButton, SendButton = _h === void 0 ? DefaultSendButton : _h;
    return (React.createElement("div", { className: "str-chat__input-flat " + (SendButton ? 'str-chat__input-flat--send-button-active' : null) + " " + (quotedMessage && !quotedMessage.parent_id ? 'str-chat__input-flat-quoted' : null) },
        React.createElement(ImageDropzone, { accept: acceptedFiles, disabled: !isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining, handleFiles: uploadNewFiles, maxNumberOfFiles: maxFilesLeft, multiple: multipleUploads },
            quotedMessage && !quotedMessage.parent_id && (React.createElement(QuotedMessagePreview, { quotedMessage: quotedMessage })),
            React.createElement("div", { className: 'str-chat__input-flat-wrapper' },
                React.createElement("div", { className: 'str-chat__input-flat--textarea-wrapper' },
                    isUploadEnabled && React.createElement(UploadsPreview, null),
                    React.createElement("div", { className: 'str-chat__emojiselect-wrapper' },
                        React.createElement(Tooltip, null, emojiPickerIsOpen ? t('Close emoji picker') : t('Open emoji picker')),
                        React.createElement("button", { "aria-label": 'Emoji picker', className: 'str-chat__input-flat-emojiselect', onClick: emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker }, cooldownRemaining ? (React.createElement("div", { className: 'str-chat__input-flat-cooldown' },
                            React.createElement(CooldownTimer, { cooldownInterval: cooldownInterval, setCooldownRemaining: setCooldownRemaining }))) : (React.createElement(EmojiIcon, null)))),
                    React.createElement(EmojiPicker, null),
                    React.createElement(ChatAutoComplete, null),
                    isUploadEnabled && !cooldownRemaining && (React.createElement("div", { className: 'str-chat__fileupload-wrapper', "data-testid": 'fileinput' },
                        React.createElement(Tooltip, null, maxFilesLeft
                            ? t('Attach files')
                            : t("You've reached the maximum number of files")),
                        React.createElement(FileUploadButton, { accepts: acceptedFiles, disabled: maxFilesLeft === 0, handleFiles: uploadNewFiles, multiple: multipleUploads },
                            React.createElement("span", { className: 'str-chat__input-flat-fileupload' },
                                React.createElement(FileUploadIcon, null)))))),
                !cooldownRemaining && React.createElement(SendButton, { sendMessage: handleSubmit })))));
};
