/* eslint-disable no-continue */
import { isDate } from '../../context/TranslationContext';
export var processMessages = function (params) {
    var _a, _b, _c;
    var disableDateSeparator = params.disableDateSeparator, hideDeletedMessages = params.hideDeletedMessages, hideNewMessageSeparator = params.hideNewMessageSeparator, lastRead = params.lastRead, messages = params.messages, separateGiphyPreview = params.separateGiphyPreview, setGiphyPreviewMessage = params.setGiphyPreviewMessage, threadList = params.threadList, userId = params.userId;
    var unread = false;
    var ephemeralMessagePresent = false;
    var lastDateSeparator;
    var newMessages = [];
    for (var i = 0; i < messages.length; i += 1) {
        var message = messages[i];
        if (hideDeletedMessages && message.type === 'deleted') {
            continue;
        }
        if (separateGiphyPreview &&
            setGiphyPreviewMessage &&
            message.type === 'ephemeral' &&
            message.command === 'giphy') {
            ephemeralMessagePresent = true;
            setGiphyPreviewMessage(message);
            continue;
        }
        var messageDate = (message.created_at && isDate(message.created_at) && message.created_at.toDateString()) || '';
        var prevMessageDate = messageDate;
        var previousMessage = messages[i - 1];
        if (i > 0 &&
            !disableDateSeparator &&
            !threadList &&
            previousMessage.created_at &&
            isDate(previousMessage.created_at)) {
            prevMessageDate = previousMessage.created_at.toDateString();
        }
        if (!unread && !hideNewMessageSeparator && !threadList) {
            unread = (lastRead && message.created_at && new Date(lastRead) < message.created_at) || false;
            // do not show date separator for current user's messages
            if (!disableDateSeparator && unread && ((_a = message.user) === null || _a === void 0 ? void 0 : _a.id) !== userId) {
                newMessages.push({
                    customType: 'message.date',
                    date: message.created_at,
                    id: message.id,
                    unread: unread,
                });
            }
        }
        if (!disableDateSeparator &&
            !threadList &&
            (i === 0 ||
                messageDate !== prevMessageDate ||
                (hideDeletedMessages &&
                    ((_b = messages[i - 1]) === null || _b === void 0 ? void 0 : _b.type) === 'deleted' &&
                    lastDateSeparator !== messageDate)) &&
            ((_c = newMessages === null || newMessages === void 0 ? void 0 : newMessages[newMessages.length - 1]) === null || _c === void 0 ? void 0 : _c.customType) !== 'message.date' // do not show two date separators in a row
        ) {
            lastDateSeparator = messageDate;
            newMessages.push({
                customType: 'message.date',
                date: message.created_at,
                id: message.id,
            }, message);
        }
        else {
            newMessages.push(message);
        }
    }
    // clean up the giphy preview component state after a Cancel action
    if (separateGiphyPreview && !ephemeralMessagePresent) {
        setGiphyPreviewMessage === null || setGiphyPreviewMessage === void 0 ? void 0 : setGiphyPreviewMessage(undefined);
    }
    return newMessages;
};
// fast since it usually iterates just the last few messages
export var getLastReceived = function (messages) {
    for (var i = messages.length - 1; i > 0; i -= 1) {
        if (messages[i].status === 'received') {
            return messages[i].id;
        }
    }
    return null;
};
export var getReadStates = function (messages, read, returnAllReadData) {
    if (read === void 0) { read = {}; }
    // create object with empty array for each message id
    var readData = {};
    Object.values(read).forEach(function (readState) {
        if (!readState.last_read)
            return;
        var userLastReadMsgId;
        // loop messages sent by current user and add read data for other users in channel
        messages.forEach(function (msg) {
            if (msg.updated_at && msg.updated_at < readState.last_read) {
                userLastReadMsgId = msg.id;
                // if true, save other user's read data for all messages they've read
                if (returnAllReadData) {
                    if (!readData[userLastReadMsgId]) {
                        readData[userLastReadMsgId] = [];
                    }
                    readData[userLastReadMsgId].push(readState.user);
                }
            }
        });
        // if true, only save read data for other user's last read message
        if (userLastReadMsgId && !returnAllReadData) {
            if (!readData[userLastReadMsgId]) {
                readData[userLastReadMsgId] = [];
            }
            readData[userLastReadMsgId].push(readState.user);
        }
    });
    return readData;
};
export var insertIntro = function (messages, headerPosition) {
    var newMessages = messages;
    var intro = { customType: 'channel.intro' };
    // if no headerPosition is set, HeaderComponent will go at the top
    if (!headerPosition) {
        newMessages.unshift(intro);
        return newMessages;
    }
    // if no messages, intro gets inserted
    if (!newMessages.length) {
        newMessages.unshift(intro);
        return newMessages;
    }
    // else loop over the messages
    for (var i = 0; i < messages.length; i += 1) {
        var message = messages[i];
        var messageTime = message.created_at && isDate(message.created_at) ? message.created_at.getTime() : null;
        var nextMessage = messages[i + 1];
        var nextMessageTime = nextMessage.created_at && isDate(nextMessage.created_at)
            ? nextMessage.created_at.getTime()
            : null;
        // header position is smaller than message time so comes after;
        if (messageTime && messageTime < headerPosition) {
            // if header position is also smaller than message time continue;
            if (nextMessageTime && nextMessageTime < headerPosition) {
                if (messages[i + 1] && messages[i + 1].customType === 'message.date')
                    continue;
                if (!nextMessageTime) {
                    newMessages.push(intro);
                    return newMessages;
                }
                continue;
            }
            else {
                newMessages.splice(i + 1, 0, intro);
                return newMessages;
            }
        }
    }
    return newMessages;
};
export var getGroupStyles = function (message, previousMessage, nextMessage, noGroupByUser) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (message.customType === 'message.date')
        return '';
    if (message.customType === 'channel.intro')
        return '';
    if (noGroupByUser || ((_a = message.attachments) === null || _a === void 0 ? void 0 : _a.length) !== 0)
        return 'single';
    var isTopMessage = !previousMessage ||
        previousMessage.customType === 'channel.intro' ||
        previousMessage.customType === 'message.date' ||
        previousMessage.type === 'system' ||
        ((_b = previousMessage.attachments) === null || _b === void 0 ? void 0 : _b.length) !== 0 ||
        ((_c = message.user) === null || _c === void 0 ? void 0 : _c.id) !== ((_d = previousMessage.user) === null || _d === void 0 ? void 0 : _d.id) ||
        previousMessage.type === 'error' ||
        previousMessage.deleted_at;
    var isBottomMessage = !nextMessage ||
        nextMessage.customType === 'message.date' ||
        nextMessage.type === 'system' ||
        nextMessage.customType === 'channel.intro' ||
        ((_e = nextMessage.attachments) === null || _e === void 0 ? void 0 : _e.length) !== 0 ||
        ((_f = message.user) === null || _f === void 0 ? void 0 : _f.id) !== ((_g = nextMessage.user) === null || _g === void 0 ? void 0 : _g.id) ||
        nextMessage.type === 'error' ||
        nextMessage.deleted_at;
    if (!isTopMessage && !isBottomMessage) {
        if (message.deleted_at || message.type === 'error')
            return 'single';
        return 'middle';
    }
    if (isBottomMessage) {
        if (isTopMessage || message.deleted_at || message.type === 'error')
            return 'single';
        return 'bottom';
    }
    if (isTopMessage)
        return 'top';
    return '';
};
