import React, { useEffect } from 'react';
import { FilePreviewer, ImagePreviewer } from 'react-file-utils';
import { useBreakpoint } from '../Message/hooks';
import { useChannelStateContext } from '../../context/ChannelStateContext';
import { useMessageInputContext } from '../../context/MessageInputContext';
export var UploadsPreview = function () {
    var _a = useChannelStateContext('UploadsPreview'), maxNumberOfFiles = _a.maxNumberOfFiles, multipleUploads = _a.multipleUploads;
    var messageInput = useMessageInputContext('UploadsPreview');
    var fileOrder = messageInput.fileOrder, fileUploads = messageInput.fileUploads, imageOrder = messageInput.imageOrder, imageUploads = messageInput.imageUploads, numberOfUploads = messageInput.numberOfUploads, removeFile = messageInput.removeFile, removeImage = messageInput.removeImage, text = messageInput.text, uploadFile = messageInput.uploadFile, uploadImage = messageInput.uploadImage, uploadNewFiles = messageInput.uploadNewFiles;
    var imagesToPreview = imageOrder.map(function (id) { return imageUploads[id]; });
    var filesToPreview = fileOrder.map(function (id) { return fileUploads[id]; });
    var device = useBreakpoint().device;
    useEffect(function () {
        var elements = document.getElementsByClassName('str-chat__send-button');
        var sendButton = elements.item(0);
        if (sendButton instanceof HTMLButtonElement) {
            if ((numberOfUploads && !text) || device !== 'full') {
                sendButton.style.display = 'block';
            }
            else {
                sendButton.style.display = 'none';
            }
        }
    }, [device, numberOfUploads, text]);
    return (React.createElement(React.Fragment, null,
        imageOrder.length > 0 && (React.createElement(ImagePreviewer, { disabled: !multipleUploads ||
                (maxNumberOfFiles !== undefined && numberOfUploads >= maxNumberOfFiles), handleFiles: uploadNewFiles, handleRemove: removeImage, handleRetry: uploadImage, imageUploads: imagesToPreview, multiple: multipleUploads })),
        fileOrder.length > 0 && (React.createElement(FilePreviewer, { handleFiles: uploadNewFiles, handleRemove: removeFile, handleRetry: uploadFile, uploads: filesToPreview }))));
};
