var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useEffect } from 'react';
import { useChatContext } from '../../../context/ChatContext';
export var useChannelTruncatedListener = function (setChannels, customHandler, forceUpdate) {
    var client = useChatContext('useChannelTruncatedListener').client;
    useEffect(function () {
        var handleEvent = function (event) {
            setChannels(function (channels) { return __spreadArray([], channels); });
            if (customHandler && typeof customHandler === 'function') {
                customHandler(setChannels, event);
            }
            if (forceUpdate) {
                forceUpdate();
            }
        };
        client.on('channel.truncated', handleEvent);
        return function () {
            client.off('channel.truncated', handleEvent);
        };
    }, [customHandler]);
};
