var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { SearchInput as DefaultSearchInput, } from './SearchInput';
import { SearchResults } from './SearchResults';
import { isChannel } from './utils';
import { useChatContext } from '../../context/ChatContext';
var UnMemoizedChannelSearch = function (props) {
    var _a = props.channelType, channelType = _a === void 0 ? 'messaging' : _a, DropdownContainer = props.DropdownContainer, onSelectResult = props.onSelectResult, _b = props.popupResults, popupResults = _b === void 0 ? false : _b, SearchEmpty = props.SearchEmpty, _c = props.searchForChannels, searchForChannels = _c === void 0 ? false : _c, searchFunction = props.searchFunction, _d = props.SearchInput, SearchInput = _d === void 0 ? DefaultSearchInput : _d, SearchLoading = props.SearchLoading, searchQueryParams = props.searchQueryParams, SearchResultItem = props.SearchResultItem, SearchResultsHeader = props.SearchResultsHeader;
    var _e = useChatContext('ChannelSearch'), client = _e.client, setActiveChannel = _e.setActiveChannel;
    var _f = useState(''), query = _f[0], setQuery = _f[1];
    var _g = useState([]), results = _g[0], setResults = _g[1];
    var _h = useState(false), resultsOpen = _h[0], setResultsOpen = _h[1];
    var _j = useState(false), searching = _j[0], setSearching = _j[1];
    var inputRef = useRef(null);
    var clearState = function () {
        setQuery('');
        setResults([]);
        setResultsOpen(false);
        setSearching(false);
    };
    useEffect(function () {
        var clickListener = function (event) {
            var _a;
            if (resultsOpen && event.target instanceof HTMLElement) {
                var isInputClick = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target);
                if (!isInputClick) {
                    clearState();
                }
            }
        };
        document.addEventListener('click', clickListener);
        return function () { return document.removeEventListener('click', clickListener); };
    }, [resultsOpen]);
    var selectResult = function (result) { return __awaiter(void 0, void 0, void 0, function () {
        var newChannel;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!client.userID)
                        return [2 /*return*/];
                    if (!isChannel(result)) return [3 /*break*/, 1];
                    setActiveChannel(result);
                    return [3 /*break*/, 3];
                case 1:
                    newChannel = client.channel(channelType, { members: [client.userID, result.id] });
                    return [4 /*yield*/, newChannel.watch()];
                case 2:
                    _a.sent();
                    setActiveChannel(newChannel);
                    _a.label = 3;
                case 3:
                    clearState();
                    return [2 /*return*/];
            }
        });
    }); };
    var getChannels = function (text) { return __awaiter(void 0, void 0, void 0, function () {
        var userResponse, channelResponse, _a, channels, users_1, users, error_1;
        var _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    if (!text || searching)
                        return [2 /*return*/];
                    setSearching(true);
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, client.queryUsers(__assign({ $or: [{ id: { $autocomplete: text } }, { name: { $autocomplete: text } }], id: { $ne: client.userID } }, (_b = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.userFilters) === null || _b === void 0 ? void 0 : _b.filters), __assign({ id: 1 }, (_c = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.userFilters) === null || _c === void 0 ? void 0 : _c.sort), __assign({ limit: 8 }, (_d = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.userFilters) === null || _d === void 0 ? void 0 : _d.options))];
                case 2:
                    userResponse = _h.sent();
                    if (!searchForChannels) return [3 /*break*/, 4];
                    channelResponse = client.queryChannels(__assign({ name: { $autocomplete: text } }, (_e = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.channelFilters) === null || _e === void 0 ? void 0 : _e.filters), ((_f = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.channelFilters) === null || _f === void 0 ? void 0 : _f.sort) || {}, __assign({ limit: 5 }, (_g = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.channelFilters) === null || _g === void 0 ? void 0 : _g.options));
                    return [4 /*yield*/, Promise.all([channelResponse, userResponse])];
                case 3:
                    _a = _h.sent(), channels = _a[0], users_1 = _a[1].users;
                    setResults(__spreadArray(__spreadArray([], channels), users_1));
                    setResultsOpen(true);
                    setSearching(false);
                    return [2 /*return*/];
                case 4: return [4 /*yield*/, Promise.resolve(userResponse)];
                case 5:
                    users = (_h.sent()).users;
                    setResults(users);
                    setResultsOpen(true);
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _h.sent();
                    clearState();
                    console.error(error_1);
                    return [3 /*break*/, 7];
                case 7:
                    setSearching(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var getChannelsThrottled = throttle(getChannels, 200);
    var onSearch = function (event) {
        event.preventDefault();
        setQuery(event.target.value);
        getChannelsThrottled(event.target.value);
    };
    var channelSearchParams = {
        setQuery: setQuery,
        setResults: setResults,
        setResultsOpen: setResultsOpen,
        setSearching: setSearching,
    };
    return (React.createElement("div", { className: 'str-chat__channel-search' },
        React.createElement(SearchInput, { channelSearchParams: channelSearchParams, inputRef: inputRef, onSearch: onSearch, query: query, searchFunction: searchFunction }),
        query && (React.createElement(SearchResults, { DropdownContainer: DropdownContainer, popupResults: popupResults, results: results, SearchEmpty: SearchEmpty, searching: searching, SearchLoading: SearchLoading, SearchResultItem: SearchResultItem, SearchResultsHeader: SearchResultsHeader, selectResult: onSelectResult || selectResult }))));
};
/**
 * The ChannelSearch component makes a query users call and displays the results in a list.
 * Clicking on a list item will navigate you into a channel with the selected user. It can be used
 * on its own or added to the ChannelList component by setting the `showChannelSearch` prop to true.
 */
export var ChannelSearch = React.memo(UnMemoizedChannelSearch);
