import { useMemo } from 'react';
export var useCreateChatContext = function (value) {
    var _a;
    var channel = value.channel, client = value.client, closeMobileNav = value.closeMobileNav, customClasses = value.customClasses, getAppSettings = value.getAppSettings, mutes = value.mutes, navOpen = value.navOpen, openMobileNav = value.openMobileNav, setActiveChannel = value.setActiveChannel, theme = value.theme, useImageFlagEmojisOnWindows = value.useImageFlagEmojisOnWindows;
    var channelCid = channel === null || channel === void 0 ? void 0 : channel.cid;
    var clientValues = "" + client.clientID + Object.keys(client.activeChannels).length + Object.keys(client.listeners).length + client.mutedChannels.length + "\n  " + ((_a = client.user) === null || _a === void 0 ? void 0 : _a.id);
    var mutedUsersLength = mutes.length;
    var chatContext = useMemo(function () { return ({
        channel: channel,
        client: client,
        closeMobileNav: closeMobileNav,
        customClasses: customClasses,
        getAppSettings: getAppSettings,
        mutes: mutes,
        navOpen: navOpen,
        openMobileNav: openMobileNav,
        setActiveChannel: setActiveChannel,
        theme: theme,
        useImageFlagEmojisOnWindows: useImageFlagEmojisOnWindows,
    }); }, [channelCid, clientValues, getAppSettings, mutedUsersLength, navOpen]);
    return chatContext;
};
