var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useEffect } from 'react';
import { useChatContext } from '../../../context/ChatContext';
export var useChannelHiddenListener = function (setChannels, customHandler) {
    var client = useChatContext('useChannelHiddenListener').client;
    useEffect(function () {
        var handleEvent = function (event) {
            if (customHandler && typeof customHandler === 'function') {
                customHandler(setChannels, event);
            }
            else {
                setChannels(function (channels) {
                    var channelIndex = channels.findIndex(function (channel) { return channel.cid === event.cid; });
                    if (channelIndex < 0)
                        return __spreadArray([], channels);
                    // Remove the hidden channel from the list.s
                    channels.splice(channelIndex, 1);
                    return __spreadArray([], channels);
                });
            }
        };
        client.on('channel.hidden', handleEvent);
        return function () {
            client.off('channel.hidden', handleEvent);
        };
    }, [customHandler]);
};
