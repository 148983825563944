var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useEffect } from 'react';
import { useChatContext } from '../../../context/ChatContext';
export var useUserPresenceChangedListener = function (setChannels) {
    var client = useChatContext('useUserPresenceChangedListener').client;
    useEffect(function () {
        var handleEvent = function (event) {
            setChannels(function (channels) {
                var newChannels = channels.map(function (channel) {
                    var _a;
                    if (!((_a = event.user) === null || _a === void 0 ? void 0 : _a.id) || !channel.state.members[event.user.id]) {
                        return channel;
                    }
                    var newChannel = channel; // dumb workaround for linter
                    newChannel.state.members[event.user.id].user = event.user;
                    return newChannel;
                });
                return __spreadArray([], newChannels);
            });
        };
        client.on('user.presence.changed', handleEvent);
        return function () {
            client.off('user.presence.changed', handleEvent);
        };
    }, []);
};
