import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useMessageListScrollManager } from './useMessageListScrollManager';
export var useScrollLocationLogic = function (params) {
    var _a = params.messages, messages = _a === void 0 ? [] : _a, _b = params.scrolledUpThreshold, scrolledUpThreshold = _b === void 0 ? 200 : _b;
    var _c = useState(false), hasNewMessages = _c[0], setHasNewMessages = _c[1];
    var _d = useState(), wrapperRect = _d[0], setWrapperRect = _d[1];
    var closeToBottom = useRef(false);
    var closeToTop = useRef(false);
    var listRef = useRef(null);
    var scrollToBottom = useCallback(function () {
        var _a, _b;
        if (!((_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo))
            return;
        (_b = listRef.current) === null || _b === void 0 ? void 0 : _b.scrollTo({
            top: listRef.current.scrollHeight,
        });
        setHasNewMessages(false);
        // this is hacky and unreliable, but that was the current implementation so not breaking it
        setTimeout(function () {
            var _a;
            (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                top: listRef.current.scrollHeight,
            });
        }, 200);
    }, [listRef]);
    useLayoutEffect(function () {
        if (listRef === null || listRef === void 0 ? void 0 : listRef.current) {
            setWrapperRect(listRef.current.getBoundingClientRect());
            scrollToBottom();
        }
    }, [listRef]);
    var updateScrollTop = useMessageListScrollManager({
        messages: messages,
        onScrollBy: function (scrollBy) { var _a; return (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy({ top: scrollBy }); },
        scrollContainerMeasures: function () {
            var _a, _b;
            return ({
                offsetHeight: ((_a = listRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) || 0,
                scrollHeight: ((_b = listRef.current) === null || _b === void 0 ? void 0 : _b.scrollHeight) || 0,
            });
        },
        scrolledUpThreshold: scrolledUpThreshold,
        scrollToBottom: scrollToBottom,
        showNewMessages: function () { return setHasNewMessages(true); },
    });
    var onScroll = useCallback(function (event) {
        var element = event.target;
        var scrollTop = element.scrollTop;
        updateScrollTop(scrollTop);
        var offsetHeight = element.offsetHeight;
        var scrollHeight = element.scrollHeight;
        closeToBottom.current = scrollHeight - (scrollTop + offsetHeight) < scrolledUpThreshold;
        closeToTop.current = scrollTop < scrolledUpThreshold;
        if (closeToBottom.current) {
            setHasNewMessages(false);
        }
    }, [updateScrollTop, closeToTop, closeToBottom, scrolledUpThreshold]);
    var onMessageLoadCaptured = useCallback(function () {
        /**
         * A load event (emitted by e.g. an <img>) was captured on a message.
         * In some cases, the loaded asset is larger than the placeholder, which means we have to scroll down.
         */
        if (closeToBottom.current && !closeToTop.current) {
            scrollToBottom();
        }
    }, [closeToTop, closeToBottom, scrollToBottom]);
    return {
        hasNewMessages: hasNewMessages,
        listRef: listRef,
        onMessageLoadCaptured: onMessageLoadCaptured,
        onScroll: onScroll,
        scrollToBottom: scrollToBottom,
        wrapperRect: wrapperRect,
    };
};
