var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from 'react';
import { FixedHeightMessage } from '../Message/FixedHeightMessage';
import { Message } from '../Message/Message';
import { MessageInput } from '../MessageInput/MessageInput';
import { MessageInputSmall } from '../MessageInput/MessageInputSmall';
import { MessageList } from '../MessageList/MessageList';
import { VirtualizedMessageList, } from '../MessageList/VirtualizedMessageList';
import { useChannelActionContext } from '../../context/ChannelActionContext';
import { useChannelStateContext } from '../../context/ChannelStateContext';
import { useChatContext } from '../../context/ChatContext';
import { useComponentContext } from '../../context/ComponentContext';
import { useTranslationContext } from '../../context/TranslationContext';
/**
 * The Thread component renders a parent Message with a list of replies
 */
export var Thread = function (props) {
    var _a = useChannelStateContext('Thread'), channel = _a.channel, channelConfig = _a.channelConfig, thread = _a.thread;
    if (!thread || (channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.replies) === false)
        return null;
    // The wrapper ensures a key variable is set and the component recreates on thread switch
    return React.createElement(ThreadInner, __assign({}, props, { key: "thread-" + thread.id + "-" + (channel === null || channel === void 0 ? void 0 : channel.cid) }));
};
var DefaultThreadHeader = function (props) {
    var closeThread = props.closeThread, thread = props.thread;
    var t = useTranslationContext('Thread').t;
    var getReplyCount = function () {
        if (!thread.reply_count)
            return '';
        if (thread.reply_count === 1)
            return t('1 reply');
        return t('{{ replyCount }} replies', {
            replyCount: thread.reply_count,
        });
    };
    return (React.createElement("div", { className: 'str-chat__thread-header' },
        React.createElement("div", { className: 'str-chat__thread-header-details' },
            React.createElement("strong", null, t('Thread')),
            React.createElement("small", null, getReplyCount())),
        React.createElement("button", { "aria-label": 'Close thread', className: 'str-chat__square-button', "data-testid": 'close-button', onClick: function (event) { return closeThread(event); } },
            React.createElement("svg", { height: '10', width: '10', xmlns: 'http://www.w3.org/2000/svg' },
                React.createElement("path", { d: 'M9.916 1.027L8.973.084 5 4.058 1.027.084l-.943.943L4.058 5 .084 8.973l.943.943L5 5.942l3.973 3.974.943-.943L5.942 5z', fillRule: 'evenodd' })))));
};
var DefaultThreadStart = function () {
    var t = useTranslationContext('Thread').t;
    return React.createElement("div", { className: 'str-chat__thread-start' }, t('Start of a new thread'));
};
var ThreadInner = function (props) {
    var additionalMessageInputProps = props.additionalMessageInputProps, additionalMessageListProps = props.additionalMessageListProps, additionalParentMessageProps = props.additionalParentMessageProps, additionalVirtualizedMessageListProps = props.additionalVirtualizedMessageListProps, _a = props.autoFocus, autoFocus = _a === void 0 ? true : _a, _b = props.fullWidth, fullWidth = _b === void 0 ? false : _b, PropInput = props.Input, PropMessage = props.Message, virtualized = props.virtualized;
    var _c = useChannelStateContext('Thread'), thread = _c.thread, threadHasMore = _c.threadHasMore, threadLoadingMore = _c.threadLoadingMore, threadMessages = _c.threadMessages;
    var _d = useChannelActionContext('Thread'), closeThread = _d.closeThread, loadMoreThread = _d.loadMoreThread;
    var customClasses = useChatContext('Thread').customClasses;
    var _e = useComponentContext('Thread'), ContextInput = _e.ThreadInput, ContextMessage = _e.Message, _f = _e.ThreadHeader, ThreadHeader = _f === void 0 ? DefaultThreadHeader : _f, _g = _e.ThreadStart, ThreadStart = _g === void 0 ? DefaultThreadStart : _g, _h = _e.VirtualMessage, VirtualMessage = _h === void 0 ? FixedHeightMessage : _h;
    var messageList = useRef(null);
    var ThreadInput = PropInput || (additionalMessageInputProps === null || additionalMessageInputProps === void 0 ? void 0 : additionalMessageInputProps.Input) || ContextInput || MessageInputSmall;
    var ThreadMessage = PropMessage || (additionalMessageListProps === null || additionalMessageListProps === void 0 ? void 0 : additionalMessageListProps.Message);
    var FallbackMessage = virtualized ? VirtualMessage : ContextMessage;
    var ThreadMessageList = virtualized ? VirtualizedMessageList : MessageList;
    useEffect(function () {
        if ((thread === null || thread === void 0 ? void 0 : thread.id) && (thread === null || thread === void 0 ? void 0 : thread.reply_count)) {
            loadMoreThread();
        }
    }, []);
    useEffect(function () {
        if (messageList.current && (threadMessages === null || threadMessages === void 0 ? void 0 : threadMessages.length)) {
            var _a = messageList.current, clientHeight = _a.clientHeight, scrollHeight = _a.scrollHeight, scrollTop = _a.scrollTop;
            var scrollDown = clientHeight + scrollTop !== scrollHeight;
            if (scrollDown) {
                messageList.current.scrollTop = scrollHeight - clientHeight;
            }
        }
    }, [threadMessages === null || threadMessages === void 0 ? void 0 : threadMessages.length]);
    if (!thread)
        return null;
    var threadClass = (customClasses === null || customClasses === void 0 ? void 0 : customClasses.thread) || 'str-chat__thread';
    return (React.createElement("div", { className: threadClass + " " + (fullWidth ? 'str-chat__thread--full' : '') },
        React.createElement(ThreadHeader, { closeThread: closeThread, thread: thread }),
        React.createElement("div", { className: 'str-chat__thread-list', ref: messageList },
            React.createElement(Message, __assign({ initialMessage: true, message: thread, Message: ThreadMessage || FallbackMessage, threadList: true }, additionalParentMessageProps)),
            React.createElement(ThreadStart, null),
            React.createElement(ThreadMessageList, __assign({ hasMore: threadHasMore, loadingMore: threadLoadingMore, loadMore: loadMoreThread, Message: ThreadMessage || FallbackMessage, messages: threadMessages || [], threadList: true }, (virtualized ? additionalVirtualizedMessageListProps : additionalMessageListProps)))),
        React.createElement(MessageInput, __assign({ focus: autoFocus, Input: ThreadInput, parent: thread, publishTypingEvent: false }, additionalMessageInputProps))));
};
