import React from 'react';
import { ReplyIcon } from './icons';
import { useTranslationContext } from '../../context/TranslationContext';
var UnMemoizedMessageRepliesCountButton = function (props) {
    var labelPlural = props.labelPlural, labelSingle = props.labelSingle, onClick = props.onClick, _a = props.reply_count, reply_count = _a === void 0 ? 0 : _a;
    var t = useTranslationContext('MessageRepliesCountButton').t;
    var singleReplyText;
    var pluralReplyText;
    if (reply_count === 1) {
        if (labelSingle) {
            singleReplyText = "1 " + labelSingle;
        }
        else {
            singleReplyText = t('1 reply');
        }
    }
    if (reply_count && reply_count > 1) {
        if (labelPlural) {
            pluralReplyText = reply_count + " " + labelPlural;
        }
        else {
            pluralReplyText = t('{{ replyCount }} replies', {
                replyCount: reply_count,
            });
        }
    }
    if (reply_count && reply_count !== 0) {
        return (React.createElement("button", { className: 'str-chat__message-replies-count-button', "data-testid": 'replies-count-button', onClick: onClick },
            React.createElement(ReplyIcon, null),
            reply_count === 1 ? singleReplyText : pluralReplyText));
    }
    return null;
};
export var MessageRepliesCountButton = React.memo(UnMemoizedMessageRepliesCountButton);
