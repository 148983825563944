var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Virtuoso, } from 'react-virtuoso';
import { GiphyPreviewMessage as DefaultGiphyPreviewMessage } from './GiphyPreviewMessage';
import { useGiphyPreview } from './hooks/useGiphyPreview';
import { useNewMessageNotification } from './hooks/useNewMessageNotification';
import { usePrependedMessagesCount } from './hooks/usePrependMessagesCount';
import { useShouldForceScrollToBottom } from './hooks/useShouldForceScrollToBottom';
import { MessageNotification as DefaultMessageNotification } from './MessageNotification';
import { MessageListNotifications as DefaultMessageListNotifications } from './MessageListNotifications';
import { processMessages } from './utils';
import { DateSeparator as DefaultDateSeparator } from '../DateSeparator/DateSeparator';
import { EmptyStateIndicator as DefaultEmptyStateIndicator } from '../EmptyStateIndicator/EmptyStateIndicator';
import { EventComponent } from '../EventComponent/EventComponent';
import { LoadingIndicator as DefaultLoadingIndicator } from '../Loading/LoadingIndicator';
import { Message, MessageSimple } from '../Message';
import { useChannelActionContext, } from '../../context/ChannelActionContext';
import { useChannelStateContext, } from '../../context/ChannelStateContext';
import { useChatContext } from '../../context/ChatContext';
import { useComponentContext } from '../../context/ComponentContext';
import { isDate } from '../../context/TranslationContext';
var PREPEND_OFFSET = Math.pow(10, 7);
var VirtualizedMessageListWithContext = function (props) {
    var additionalVirtuosoProps = props.additionalVirtuosoProps, channel = props.channel, closeReactionSelectorOnClick = props.closeReactionSelectorOnClick, customMessageRenderer = props.customMessageRenderer, defaultItemHeight = props.defaultItemHeight, _a = props.disableDateSeparator, disableDateSeparator = _a === void 0 ? true : _a, hasMore = props.hasMore, _b = props.hideDeletedMessages, hideDeletedMessages = _b === void 0 ? false : _b, _c = props.hideNewMessageSeparator, hideNewMessageSeparator = _c === void 0 ? false : _c, loadingMore = props.loadingMore, loadMore = props.loadMore, propMessage = props.Message, _d = props.messageLimit, messageLimit = _d === void 0 ? 100 : _d, messages = props.messages, notifications = props.notifications, _e = props.overscan, overscan = _e === void 0 ? 0 : _e, 
    // TODO: refactor to scrollSeekPlaceHolderConfiguration and components.ScrollSeekPlaceholder, like the Virtuoso Component
    scrollSeekPlaceHolder = props.scrollSeekPlaceHolder, _f = props.scrollToLatestMessageOnFocus, scrollToLatestMessageOnFocus = _f === void 0 ? false : _f, _g = props.separateGiphyPreview, separateGiphyPreview = _g === void 0 ? false : _g, _h = props.shouldGroupByUser, shouldGroupByUser = _h === void 0 ? false : _h, _j = props.stickToBottomScrollBehavior, stickToBottomScrollBehavior = _j === void 0 ? 'smooth' : _j;
    var _k = useComponentContext('VirtualizedMessageList'), _l = _k.DateSeparator, DateSeparator = _l === void 0 ? DefaultDateSeparator : _l, _m = _k.EmptyStateIndicator, EmptyStateIndicator = _m === void 0 ? DefaultEmptyStateIndicator : _m, _o = _k.GiphyPreviewMessage, GiphyPreviewMessage = _o === void 0 ? DefaultGiphyPreviewMessage : _o, _p = _k.LoadingIndicator, LoadingIndicator = _p === void 0 ? DefaultLoadingIndicator : _p, _q = _k.MessageListNotifications, MessageListNotifications = _q === void 0 ? DefaultMessageListNotifications : _q, _r = _k.MessageNotification, MessageNotification = _r === void 0 ? DefaultMessageNotification : _r, _s = _k.MessageSystem, MessageSystem = _s === void 0 ? EventComponent : _s, _t = _k.TypingIndicator, TypingIndicator = _t === void 0 ? null : _t, _u = _k.VirtualMessage, contextMessage = _u === void 0 ? MessageSimple : _u;
    var _v = useChatContext('VirtualizedMessageList'), client = _v.client, customClasses = _v.customClasses;
    var lastRead = useMemo(function () { var _a; return (_a = channel.lastRead) === null || _a === void 0 ? void 0 : _a.call(channel); }, [channel]);
    var MessageUIComponent = propMessage || contextMessage;
    var _w = useGiphyPreview(separateGiphyPreview), giphyPreviewMessage = _w.giphyPreviewMessage, setGiphyPreviewMessage = _w.setGiphyPreviewMessage;
    var processedMessages = useMemo(function () {
        if (typeof messages === 'undefined') {
            return [];
        }
        if (disableDateSeparator &&
            !hideDeletedMessages &&
            hideNewMessageSeparator &&
            !separateGiphyPreview) {
            return messages;
        }
        return processMessages({
            disableDateSeparator: disableDateSeparator,
            hideDeletedMessages: hideDeletedMessages,
            hideNewMessageSeparator: hideNewMessageSeparator,
            lastRead: lastRead,
            messages: messages,
            separateGiphyPreview: separateGiphyPreview,
            setGiphyPreviewMessage: setGiphyPreviewMessage,
            userId: client.userID || '',
        });
    }, [
        disableDateSeparator,
        hideDeletedMessages,
        hideNewMessageSeparator,
        lastRead,
        messages,
        messages === null || messages === void 0 ? void 0 : messages.length,
        client.userID,
    ]);
    var virtuoso = useRef(null);
    var _x = useNewMessageNotification(processedMessages, client.userID), atBottom = _x.atBottom, newMessagesNotification = _x.newMessagesNotification, setNewMessagesNotification = _x.setNewMessagesNotification;
    var scrollToBottom = useCallback(function () {
        if (virtuoso.current) {
            virtuoso.current.scrollToIndex(processedMessages.length - 1);
        }
        setNewMessagesNotification(false);
    }, [virtuoso, processedMessages, setNewMessagesNotification, processedMessages.length]);
    var scrollToBottomIfConfigured = useCallback(function (event) {
        if (scrollToLatestMessageOnFocus && event.target === window) {
            setTimeout(scrollToBottom, 100);
        }
    }, [scrollToLatestMessageOnFocus, scrollToBottom]);
    useEffect(function () {
        if (typeof window !== 'undefined') {
            window.addEventListener('focus', scrollToBottomIfConfigured);
        }
        return function () { return window.removeEventListener('focus', scrollToBottomIfConfigured); };
    }, [scrollToBottomIfConfigured]);
    var numItemsPrepended = usePrependedMessagesCount(processedMessages);
    var shouldForceScrollToBottom = useShouldForceScrollToBottom(processedMessages, client.userID);
    var followOutput = function (isAtBottom) {
        if (shouldForceScrollToBottom()) {
            return isAtBottom ? stickToBottomScrollBehavior : 'auto';
        }
        // a message from another user has been received - don't scroll to bottom unless already there
        return isAtBottom ? stickToBottomScrollBehavior : false;
    };
    var messageRenderer = useCallback(function (messageList, virtuosoIndex) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var streamMessageIndex = virtuosoIndex + numItemsPrepended - PREPEND_OFFSET;
        // use custom renderer supplied by client if present and skip the rest
        if (customMessageRenderer) {
            return customMessageRenderer(messageList, streamMessageIndex);
        }
        var message = messageList[streamMessageIndex];
        if (message.customType === 'message.date' && message.date && isDate(message.date)) {
            return React.createElement(DateSeparator, { date: message.date, unread: message.unread });
        }
        if (!message)
            return React.createElement("div", { style: { height: '1px' } }); // returning null or zero height breaks the virtuoso
        if (message.type === 'system') {
            return React.createElement(MessageSystem, { message: message });
        }
        var groupedByUser = shouldGroupByUser &&
            streamMessageIndex > 0 &&
            ((_a = message.user) === null || _a === void 0 ? void 0 : _a.id) === ((_b = messageList[streamMessageIndex - 1].user) === null || _b === void 0 ? void 0 : _b.id);
        var firstOfGroup = shouldGroupByUser && ((_c = message.user) === null || _c === void 0 ? void 0 : _c.id) !== ((_e = (_d = messageList[streamMessageIndex - 1]) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.id);
        var endOfGroup = shouldGroupByUser && ((_f = message.user) === null || _f === void 0 ? void 0 : _f.id) !== ((_h = (_g = messageList[streamMessageIndex + 1]) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.id);
        return (React.createElement(Message, { closeReactionSelectorOnClick: closeReactionSelectorOnClick, customMessageActions: props.customMessageActions, endOfGroup: endOfGroup, firstOfGroup: firstOfGroup, groupedByUser: groupedByUser, message: message, Message: MessageUIComponent, messageActions: props.messageActions }));
    }, [customMessageRenderer, shouldGroupByUser, numItemsPrepended]);
    var virtuosoComponents = useMemo(function () {
        var EmptyPlaceholder = function () { return (React.createElement(React.Fragment, null, EmptyStateIndicator && React.createElement(EmptyStateIndicator, { listType: 'message' }))); };
        var Header = function () {
            return loadingMore ? (React.createElement("div", { className: 'str-chat__virtual-list__loading' },
                React.createElement(LoadingIndicator, { size: 20 }))) : (React.createElement(React.Fragment, null));
        };
        var virtualMessageClass = (customClasses === null || customClasses === void 0 ? void 0 : customClasses.virtualMessage) || 'str-chat__virtual-list-message-wrapper';
        // using 'display: inline-block' traps CSS margins of the item elements, preventing incorrect item measurements
        var Item = function (props) { return React.createElement("div", __assign({}, props, { className: virtualMessageClass })); };
        var Footer = function () {
            return TypingIndicator ? React.createElement(TypingIndicator, { avatarSize: 24 }) : React.createElement(React.Fragment, null);
        };
        return {
            EmptyPlaceholder: EmptyPlaceholder,
            Footer: Footer,
            Header: Header,
            Item: Item,
        };
    }, [loadingMore]);
    var atBottomStateChange = function (isAtBottom) {
        atBottom.current = isAtBottom;
        if (isAtBottom && newMessagesNotification) {
            setNewMessagesNotification(false);
        }
    };
    var startReached = function () {
        if (hasMore && loadMore) {
            loadMore(messageLimit);
        }
    };
    if (!processedMessages)
        return null;
    var virtualizedMessageListClass = (customClasses === null || customClasses === void 0 ? void 0 : customClasses.virtualizedMessageList) || 'str-chat__virtual-list';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: virtualizedMessageListClass },
            React.createElement(Virtuoso, __assign({ atBottomStateChange: atBottomStateChange, components: virtuosoComponents, firstItemIndex: PREPEND_OFFSET - numItemsPrepended, followOutput: followOutput, initialTopMostItemIndex: processedMessages.length ? processedMessages.length - 1 : 0, itemContent: function (i) { return messageRenderer(processedMessages, i); }, overscan: overscan, ref: virtuoso, startReached: startReached, style: { overflowX: 'hidden' }, totalCount: processedMessages.length }, additionalVirtuosoProps, (scrollSeekPlaceHolder ? { scrollSeek: scrollSeekPlaceHolder } : {}), (defaultItemHeight ? { defaultItemHeight: defaultItemHeight } : {})))),
        React.createElement(MessageListNotifications, { hasNewMessages: newMessagesNotification, MessageNotification: MessageNotification, notifications: notifications, scrollToBottom: scrollToBottom }),
        giphyPreviewMessage && React.createElement(GiphyPreviewMessage, { message: giphyPreviewMessage })));
};
/**
 * The VirtualizedMessageList component renders a list of messages in a virtualized list.
 * It is a consumer of the React contexts set in [Channel](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Channel/Channel.tsx).
 *
 * **Note**: It works well when there are thousands of messages in a channel, it has a shortcoming though - the message UI should have a fixed height.
 */
export function VirtualizedMessageList(props) {
    var loadMore = useChannelActionContext('VirtualizedMessageList').loadMore;
    var _a = useChannelStateContext('VirtualizedMessageList'), channel = _a.channel, hasMore = _a.hasMore, loadingMore = _a.loadingMore, contextMessages = _a.messages, notifications = _a.notifications;
    var messages = props.messages || contextMessages;
    return (React.createElement(VirtualizedMessageListWithContext, __assign({ channel: channel, hasMore: !!hasMore, loadingMore: !!loadingMore, loadMore: loadMore, messages: messages, notifications: notifications }, props)));
}
