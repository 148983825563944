var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useCallLoadMore } from './hooks/useCallLoadMore';
import { useEnrichedMessages } from './hooks/useEnrichedMessages';
import { useMessageListElements } from './hooks/useMessageListElements';
import { useScrollLocationLogic } from './hooks/useScrollLocationLogic';
import { Center } from './Center';
import { MessageNotification as DefaultMessageNotification } from './MessageNotification';
import { MessageListNotifications as DefaultMessageListNotifications } from './MessageListNotifications';
import { useChannelActionContext, } from '../../context/ChannelActionContext';
import { useChannelStateContext, } from '../../context/ChannelStateContext';
import { useChatContext } from '../../context/ChatContext';
import { useComponentContext } from '../../context/ComponentContext';
import { EmptyStateIndicator as DefaultEmptyStateIndicator } from '../EmptyStateIndicator';
import { InfiniteScroll } from '../InfiniteScrollPaginator';
import { LoadingIndicator as DefaultLoadingIndicator } from '../Loading';
import { defaultPinPermissions, MESSAGE_ACTIONS } from '../Message/utils';
import { TypingIndicator as DefaultTypingIndicator } from '../TypingIndicator';
var useInternalInfiniteScrollProps = function (props) {
    var _a = useComponentContext('useInternalInfiniteScrollProps').LoadingIndicator, LoadingIndicator = _a === void 0 ? DefaultLoadingIndicator : _a;
    return __assign({ hasMore: props.hasMore, isLoading: props.loadingMore, loader: (React.createElement(Center, { key: 'loadingindicator' },
            React.createElement(LoadingIndicator, { size: 20 }))), loadMore: useCallLoadMore(props.loadMore, props.messageLimit || 100) }, props.internalInfiniteScrollProps);
};
var MessageListWithContext = function (props) {
    var channel = props.channel, _a = props.disableDateSeparator, disableDateSeparator = _a === void 0 ? false : _a, groupStyles = props.groupStyles, _b = props.hideDeletedMessages, hideDeletedMessages = _b === void 0 ? false : _b, _c = props.hideNewMessageSeparator, hideNewMessageSeparator = _c === void 0 ? false : _c, _d = props.messageActions, messageActions = _d === void 0 ? Object.keys(MESSAGE_ACTIONS) : _d, _e = props.messages, messages = _e === void 0 ? [] : _e, notifications = props.notifications, _f = props.noGroupByUser, noGroupByUser = _f === void 0 ? false : _f, _g = props.pinPermissions, pinPermissions = _g === void 0 ? defaultPinPermissions : _g, // @deprecated in favor of `channelCapabilities` - TODO: remove in next major release
    _h = props.returnAllReadData, // @deprecated in favor of `channelCapabilities` - TODO: remove in next major release
    returnAllReadData = _h === void 0 ? false : _h, _j = props.threadList, threadList = _j === void 0 ? false : _j, _k = props.unsafeHTML, unsafeHTML = _k === void 0 ? false : _k, headerPosition = props.headerPosition, read = props.read;
    var customClasses = useChatContext('MessageList').customClasses;
    var _l = useComponentContext('MessageList'), _m = _l.EmptyStateIndicator, EmptyStateIndicator = _m === void 0 ? DefaultEmptyStateIndicator : _m, _o = _l.MessageListNotifications, MessageListNotifications = _o === void 0 ? DefaultMessageListNotifications : _o, _p = _l.MessageNotification, MessageNotification = _p === void 0 ? DefaultMessageNotification : _p, _q = _l.TypingIndicator, TypingIndicator = _q === void 0 ? DefaultTypingIndicator : _q;
    var _r = useScrollLocationLogic({
        messages: messages,
        scrolledUpThreshold: props.scrolledUpThreshold,
    }), hasNewMessages = _r.hasNewMessages, listRef = _r.listRef, onMessageLoadCaptured = _r.onMessageLoadCaptured, onScroll = _r.onScroll, scrollToBottom = _r.scrollToBottom, wrapperRect = _r.wrapperRect;
    var _s = useEnrichedMessages({
        channel: channel,
        disableDateSeparator: disableDateSeparator,
        groupStyles: groupStyles,
        headerPosition: headerPosition,
        hideDeletedMessages: hideDeletedMessages,
        hideNewMessageSeparator: hideNewMessageSeparator,
        messages: messages,
        noGroupByUser: noGroupByUser,
        threadList: threadList,
    }), messageGroupStyles = _s.messageGroupStyles, enrichedMessages = _s.messages;
    var elements = useMessageListElements({
        enrichedMessages: enrichedMessages,
        internalMessageProps: {
            additionalMessageInputProps: props.additionalMessageInputProps,
            closeReactionSelectorOnClick: props.closeReactionSelectorOnClick,
            customMessageActions: props.customMessageActions,
            disableQuotedMessages: props.disableQuotedMessages,
            formatDate: props.formatDate,
            getDeleteMessageErrorNotification: props.getDeleteMessageErrorNotification,
            getFlagMessageErrorNotification: props.getFlagMessageErrorNotification,
            getFlagMessageSuccessNotification: props.getFlagMessageSuccessNotification,
            getMuteUserErrorNotification: props.getMuteUserErrorNotification,
            getMuteUserSuccessNotification: props.getMuteUserSuccessNotification,
            getPinMessageErrorNotification: props.getPinMessageErrorNotification,
            Message: props.Message,
            messageActions: messageActions,
            messageListRect: wrapperRect,
            onlySenderCanEdit: props.onlySenderCanEdit,
            onMentionsClick: props.onMentionsClick,
            onMentionsHover: props.onMentionsHover,
            onUserClick: props.onUserClick,
            onUserHover: props.onUserHover,
            openThread: props.openThread,
            pinPermissions: pinPermissions,
            renderText: props.renderText,
            retrySendMessage: props.retrySendMessage,
            unsafeHTML: unsafeHTML,
        },
        messageGroupStyles: messageGroupStyles,
        onMessageLoadCaptured: onMessageLoadCaptured,
        read: read,
        returnAllReadData: returnAllReadData,
        threadList: threadList,
    });
    var finalInternalInfiniteScrollProps = useInternalInfiniteScrollProps(props);
    var messageListClass = (customClasses === null || customClasses === void 0 ? void 0 : customClasses.messageList) || 'str-chat__list';
    var threadListClass = threadList ? (customClasses === null || customClasses === void 0 ? void 0 : customClasses.threadList) || 'str-chat__list--thread' : '';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: messageListClass + " " + threadListClass, onScroll: onScroll, ref: listRef }, !elements.length ? (React.createElement(EmptyStateIndicator, { listType: 'message' })) : (React.createElement(InfiniteScroll, __assign({ className: 'str-chat__reverse-infinite-scroll', "data-testid": 'reverse-infinite-scroll', isReverse: true, useWindow: false }, finalInternalInfiniteScrollProps),
            React.createElement("ul", { className: 'str-chat__ul' }, elements),
            React.createElement(TypingIndicator, { threadList: threadList }),
            React.createElement("div", { key: 'bottom' })))),
        React.createElement(MessageListNotifications, { hasNewMessages: hasNewMessages, MessageNotification: MessageNotification, notifications: notifications, scrollToBottom: scrollToBottom })));
};
/**
 * The MessageList component renders a list of Messages.
 * It is a consumer of the following contexts:
 * - [ChannelStateContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_state_context/)
 * - [ChannelActionContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_action_context/)
 * - [ComponentContext](https://getstream.io/chat/docs/sdk/react/contexts/component_context/)
 * - [TypingContext](https://getstream.io/chat/docs/sdk/react/contexts/typing_context/)
 */
export var MessageList = function (props) {
    var loadMore = useChannelActionContext('MessageList').loadMore;
    var _a = useChannelStateContext('MessageList'), membersPropToNotPass = _a.members, // eslint-disable-line @typescript-eslint/no-unused-vars
    mutesPropToNotPass = _a.mutes, // eslint-disable-line @typescript-eslint/no-unused-vars
    watchersPropToNotPass = _a.watchers, // eslint-disable-line @typescript-eslint/no-unused-vars
    restChannelStateContext = __rest(_a, ["members", "mutes", "watchers"]);
    return (React.createElement(MessageListWithContext, __assign({ loadMore: loadMore }, restChannelStateContext, props)));
};
