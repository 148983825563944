var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
export var channelReducer = function (state, action) {
    var _a;
    switch (action.type) {
        case 'closeThread': {
            return __assign(__assign({}, state), { thread: null, threadLoadingMore: false, threadMessages: [] });
        }
        case 'copyMessagesFromChannel': {
            var channel = action.channel, parentId = action.parentId;
            return __assign(__assign({}, state), { messages: __spreadArray([], channel.state.messages), pinnedMessages: __spreadArray([], channel.state.pinnedMessages), threadMessages: parentId
                    ? __assign({}, channel.state.threads)[parentId] || []
                    : state.threadMessages });
        }
        case 'copyStateFromChannelOnEvent': {
            var channel = action.channel;
            return __assign(__assign({}, state), { members: __assign({}, channel.state.members), messages: __spreadArray([], channel.state.messages), pinnedMessages: __spreadArray([], channel.state.pinnedMessages), read: __assign({}, channel.state.read), watcherCount: channel.state.watcher_count, watchers: __assign({}, channel.state.watchers) });
        }
        case 'initStateFromChannel': {
            var channel = action.channel;
            return __assign(__assign({}, state), { loading: false, members: __assign({}, channel.state.members), messages: __spreadArray([], channel.state.messages), pinnedMessages: __spreadArray([], channel.state.pinnedMessages), read: __assign({}, channel.state.read), watcherCount: channel.state.watcher_count, watchers: __assign({}, channel.state.watchers) });
        }
        case 'loadMoreFinished': {
            var hasMore = action.hasMore, messages = action.messages;
            return __assign(__assign({}, state), { hasMore: hasMore, loadingMore: false, messages: messages });
        }
        case 'loadMoreThreadFinished': {
            var threadHasMore = action.threadHasMore, threadMessages = action.threadMessages;
            return __assign(__assign({}, state), { threadHasMore: threadHasMore, threadLoadingMore: false, threadMessages: threadMessages });
        }
        case 'openThread': {
            var channel = action.channel, message = action.message;
            return __assign(__assign({}, state), { thread: message, threadMessages: message.id ? __assign({}, channel.state.threads)[message.id] || [] : [] });
        }
        case 'setError': {
            var error = action.error;
            return __assign(__assign({}, state), { error: error });
        }
        case 'setLoadingMore': {
            var loadingMore = action.loadingMore;
            return __assign(__assign({}, state), { loadingMore: loadingMore });
        }
        case 'setThread': {
            var message = action.message;
            return __assign(__assign({}, state), { thread: message });
        }
        case 'setTyping': {
            var channel = action.channel;
            return __assign(__assign({}, state), { typing: __assign({}, channel.state.typing) });
        }
        case 'startLoadingThread': {
            return __assign(__assign({}, state), { threadLoadingMore: true });
        }
        case 'updateThreadOnEvent': {
            var channel = action.channel, message = action.message;
            if (!state.thread)
                return state;
            return __assign(__assign({}, state), { thread: (message === null || message === void 0 ? void 0 : message.id) === state.thread.id ? channel.state.formatMessage(message) : state.thread, threadMessages: ((_a = state.thread) === null || _a === void 0 ? void 0 : _a.id) ? __assign({}, channel.state.threads)[state.thread.id] || [] : [] });
        }
        default:
            return state;
    }
};
export var initialState = {
    error: null,
    hasMore: true,
    loading: true,
    loadingMore: false,
    members: {},
    messages: [],
    pinnedMessages: [],
    read: {},
    thread: null,
    threadHasMore: true,
    threadLoadingMore: false,
    threadMessages: [],
    typing: {},
    watcherCount: 0,
    watchers: {},
};
