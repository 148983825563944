import React from 'react';
import { FileUploadButton, ImageDropzone } from 'react-file-utils';
import { EmojiPicker } from './EmojiPicker';
import { CooldownTimer as DefaultCooldownTimer } from './hooks/useCooldownTimer';
import { EmojiIconSmall as DefaultEmojiIcon, FileUploadIconFlat as DefaultFileUploadIcon, SendButton as DefaultSendButton, } from './icons';
import { UploadsPreview } from './UploadsPreview';
import { ChatAutoComplete } from '../ChatAutoComplete/ChatAutoComplete';
import { Tooltip } from '../Tooltip/Tooltip';
import { useChannelStateContext } from '../../context/ChannelStateContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { useMessageInputContext } from '../../context/MessageInputContext';
import { useComponentContext } from '../../context/ComponentContext';
import { QuotedMessagePreview as DefaultQuotedMessagePreview } from './QuotedMessagePreview';
export var MessageInputSmall = function () {
    var _a = useChannelStateContext('MessageInputSmall'), acceptedFiles = _a.acceptedFiles, multipleUploads = _a.multipleUploads, quotedMessage = _a.quotedMessage;
    var t = useTranslationContext('MessageInputSmall').t;
    var _b = useMessageInputContext('MessageInputSmall'), closeEmojiPicker = _b.closeEmojiPicker, cooldownInterval = _b.cooldownInterval, cooldownRemaining = _b.cooldownRemaining, emojiPickerIsOpen = _b.emojiPickerIsOpen, handleSubmit = _b.handleSubmit, isUploadEnabled = _b.isUploadEnabled, maxFilesLeft = _b.maxFilesLeft, openEmojiPicker = _b.openEmojiPicker, setCooldownRemaining = _b.setCooldownRemaining, uploadNewFiles = _b.uploadNewFiles;
    var _c = useComponentContext('MessageInputSmall'), _d = _c.CooldownTimer, CooldownTimer = _d === void 0 ? DefaultCooldownTimer : _d, _e = _c.EmojiIcon, EmojiIcon = _e === void 0 ? DefaultEmojiIcon : _e, _f = _c.FileUploadIcon, FileUploadIcon = _f === void 0 ? DefaultFileUploadIcon : _f, _g = _c.SendButton, SendButton = _g === void 0 ? DefaultSendButton : _g, _h = _c.QuotedMessagePreview, QuotedMessagePreview = _h === void 0 ? DefaultQuotedMessagePreview : _h;
    return (React.createElement("div", { className: 'str-chat__small-message-input__wrapper' },
        React.createElement(ImageDropzone, { accept: acceptedFiles, disabled: !isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining, handleFiles: uploadNewFiles, maxNumberOfFiles: maxFilesLeft, multiple: multipleUploads },
            React.createElement("div", { className: "str-chat__small-message-input " + (SendButton ? 'str-chat__small-message-input--send-button-active' : null) + " " + (quotedMessage && quotedMessage.parent_id ? 'str-chat__input-flat-quoted' : null) },
                quotedMessage && quotedMessage.parent_id && (React.createElement(QuotedMessagePreview, { quotedMessage: quotedMessage })),
                React.createElement("div", { className: 'str-chat__small-message-input--textarea-wrapper' },
                    isUploadEnabled && React.createElement(UploadsPreview, null),
                    React.createElement(ChatAutoComplete, null),
                    cooldownRemaining ? (React.createElement("div", { className: 'str-chat__input-small-cooldown' },
                        React.createElement(CooldownTimer, { cooldownInterval: cooldownInterval, setCooldownRemaining: setCooldownRemaining }))) : (React.createElement(React.Fragment, null,
                        isUploadEnabled && (React.createElement("div", { className: 'str-chat__fileupload-wrapper', "data-testid": 'fileinput' },
                            React.createElement(Tooltip, null, maxFilesLeft
                                ? t('Attach files')
                                : t("You've reached the maximum number of files")),
                            React.createElement(FileUploadButton, { accepts: acceptedFiles, disabled: maxFilesLeft === 0, handleFiles: uploadNewFiles, multiple: multipleUploads },
                                React.createElement("span", { className: 'str-chat__small-message-input-fileupload' },
                                    React.createElement(FileUploadIcon, null))))),
                        React.createElement("div", { className: 'str-chat__emojiselect-wrapper' },
                            React.createElement(Tooltip, null, emojiPickerIsOpen ? t('Close emoji picker') : t('Open emoji picker')),
                            React.createElement("button", { "aria-label": 'Emoji picker', className: 'str-chat__small-message-input-emojiselect', onClick: emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker },
                                React.createElement(EmojiIcon, null))))),
                    React.createElement(EmojiPicker, { small: true })),
                !cooldownRemaining && React.createElement(SendButton, { sendMessage: handleSubmit })))));
};
