import React, { useCallback, useEffect, useState } from 'react';
import { isChannel } from './utils';
import { Avatar } from '../Avatar/Avatar';
import { useBreakpoint } from '../Message/hooks/useBreakpoint';
import { useTranslationContext } from '../../context/TranslationContext';
var DefaultDropdownContainer = function (props) {
    var focusedUser = props.focusedUser, results = props.results, _a = props.SearchResultItem, SearchResultItem = _a === void 0 ? DefaultSearchResultItem : _a, selectResult = props.selectResult;
    return (React.createElement(React.Fragment, null, results.map(function (result, index) { return (React.createElement(SearchResultItem, { focusedUser: focusedUser, index: index, key: index, result: result, selectResult: selectResult })); })));
};
var DefaultSearchResultItem = function (props) {
    var _a, _b;
    var focusedUser = props.focusedUser, index = props.index, result = props.result, selectResult = props.selectResult;
    var focused = focusedUser === index;
    if (isChannel(result)) {
        var channel_1 = result;
        return (React.createElement("button", { "aria-label": "Select Channel: " + (((_a = channel_1.data) === null || _a === void 0 ? void 0 : _a.name) || ''), className: "str-chat__channel-search-result " + (focused ? 'focused' : ''), onClick: function () { return selectResult(channel_1); } },
            React.createElement("div", { className: 'result-hashtag' }, "#"),
            React.createElement("p", { className: 'channel-search__result-text' }, (_b = channel_1.data) === null || _b === void 0 ? void 0 : _b.name)));
    }
    else {
        return (React.createElement("button", { "aria-label": "Select User Channel: " + (result.name || ''), className: "str-chat__channel-search-result " + (focused ? 'focused' : ''), onClick: function () { return selectResult(result); } },
            React.createElement(Avatar, { image: result.image, user: result }),
            result.name || result.id));
    }
};
export var SearchResults = function (props) {
    var _a = props.DropdownContainer, DropdownContainer = _a === void 0 ? DefaultDropdownContainer : _a, popupResults = props.popupResults, results = props.results, searching = props.searching, SearchEmpty = props.SearchEmpty, SearchResultsHeader = props.SearchResultsHeader, SearchLoading = props.SearchLoading, _b = props.SearchResultItem, SearchResultItem = _b === void 0 ? DefaultSearchResultItem : _b, selectResult = props.selectResult;
    var t = useTranslationContext('SearchResults').t;
    var _c = useState(), focusedUser = _c[0], setFocusedUser = _c[1];
    var device = useBreakpoint().device;
    var containerStyle = popupResults && device === 'full' ? 'popup' : 'inline';
    var ResultsContainer = function (_a) {
        var children = _a.children;
        return (React.createElement("div", { className: "str-chat__channel-search-container " + containerStyle }, children));
    };
    var handleKeyDown = useCallback(function (event) {
        if (event.key === 'ArrowUp') {
            setFocusedUser(function (prevFocused) {
                if (prevFocused === undefined)
                    return 0;
                return prevFocused === 0 ? results.length - 1 : prevFocused - 1;
            });
        }
        if (event.key === 'ArrowDown') {
            setFocusedUser(function (prevFocused) {
                if (prevFocused === undefined)
                    return 0;
                return prevFocused === results.length - 1 ? 0 : prevFocused + 1;
            });
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            if (focusedUser !== undefined) {
                selectResult(results[focusedUser]);
                return setFocusedUser(undefined);
            }
        }
    }, [focusedUser]);
    useEffect(function () {
        document.addEventListener('keydown', handleKeyDown, false);
        return function () { return document.removeEventListener('keydown', handleKeyDown); };
    }, [handleKeyDown]);
    if (searching) {
        return (React.createElement(ResultsContainer, null, SearchLoading ? (React.createElement(SearchLoading, null)) : (React.createElement("div", { className: 'str-chat__channel-search-container-searching' }, t('Searching...')))));
    }
    if (!results.length) {
        return (React.createElement(ResultsContainer, null, SearchEmpty ? (React.createElement(SearchEmpty, null)) : (React.createElement("div", { "aria-live": 'polite', className: 'str-chat__channel-search-container-empty' }, t('No results found')))));
    }
    return (React.createElement(ResultsContainer, null,
        SearchResultsHeader && React.createElement(SearchResultsHeader, null),
        React.createElement(DropdownContainer, { focusedUser: focusedUser, results: results, SearchResultItem: SearchResultItem, selectResult: selectResult })));
};
